// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

const partners = new Swiper(".partners", {
  slidesPerView: 3,
  spaceBetween: 20,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,

  autoplay: {
    delay: 3000,
    // disableOnInteraction: false,
    // pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    // clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 6,
      spaceBetween: 20,
    },
  },
});

function initializeSwiper(selector, speed) {
  return new Swiper(selector, {
    // Optional parameters
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: 1,
    loop: true,
    speed: speed,

    allowTouchMove: false,
    slidesOffsetAfter: 0,
    loopFillGroupWithBlank: false,

    // Enabled autoplay mode
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      0: {
        slidesPerView: 1.25,
      },
      768: {
        slidesPerView: 2.25,
      },
      1200: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 3.25,
      },
      1600: {
        slidesPerView: 4,
      },
    },
  });
}

const testimonialTwo = initializeSwiper(".testimonial-slider-two", 6000);
const testimonialOne = initializeSwiper(".testimonial-slider-one", 16000);
